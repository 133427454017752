import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { envData } from "../../envs";
import MathProctorTestComponent from "../ProctorComponents/proctor";
import Button from "@mui/material/Button";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import "./math.css";
import { isMobile } from "react-device-detect";
// import ExamPageActivityMonitorComponent from "./ExamPageActivityMonitorComponent";
function MathComponent() {
  const [startMathTest, setStartMathTest] = useState(false);
  const [studentMetaData, setStudentData] = useState([]);
  const [examType, setExamType] = useState([]);
  const location = useLocation();
  const isRouteForPractice =
    location.pathname === "/dash/practiceMath" ||
    location.pathname === "/dash/practiceCogat";

  const handleMathStartTest = () => setStartMathTest(!startMathTest);
  const getStudentMetaData = async () => {
    const token = localStorage.getItem("token");
    try {
      const req = await axios.get(`${envData.baseApiUrl}/v1/students`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setStudentData(req.data);
      const examTypeArray = req.data.exams.filter(
        (exam) => exam.examId === "EID00005"
      );
      setExamType(examTypeArray);
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  useEffect(() => {
    getStudentMetaData();
  }, []);
  // console.log(studentMetaData);
  return (
    <div
      style={{
        // height: "100%",
        display: "flex",
        overflowY: "hidden",
        overflowX: "auto",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {/* <ExamPageActivityMonitorComponent /> */}
      {!startMathTest && (
        <>
          <h5 style={{ padding: "10px", textAlign: "center" }}>
            Welcome to Your Online MathXCEL Test!
          </h5>
          <div
            style={{ marginTop: "20px", width: isMobile ? "90%" : "60%" }}
            className="scrollable-list"
          >
            <p>
              You’re stepping into an exciting challenge that lets you showcase
              your problem-solving skills and mathematical thinking. Stay
              confident, take your time, and trust your preparation—you’ve got
              everything it takes to succeed. This is your time to shine!
            </p>
            <ol>
              <li>
                <strong>Exam Overview:</strong>
                <ul>
                  <li>
                    <strong>Duration:</strong> 80 minutes
                  </li>
                  <li>
                    <strong>Number of Questions</strong>: 25
                  </li>
                  <li>
                    <strong>Question Types:</strong>
                    <ul>
                      <li>
                        Multiple Choice Questions: Choose the correct answer
                        from the options provided.
                      </li>
                      <li>
                        Open-Ended Questions: Type your answer in the provided
                        text box.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <strong>Important Reminder:</strong>
                    Please ensure your screenshare, camera and microphone are
                    turned on for the duration of the test.{" "}
                    <strong>
                      Keep your screen full, it will give you maximum possible
                      user experience.
                    </strong>
                  </li>
                </ul>
              </li>
              <li>
                Stay focused, manage your time well, and give it your best
                effort. Good luck—you can do it!
              </li>
            </ol>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
                margin: "10px",
              }}
            >
              <Button
                // style={{ marginBottom: "30px" }}
                variant="contained"
                onClick={handleMathStartTest}
                // disabled={true}
                disabled={examType[0]?.isExamCompleted}
              >
                <PlayArrowIcon style={{ margin: "0 7px" }} />
                start math test
              </Button>
            </div>
          </div>
        </>
      )}
      {startMathTest && (
        <MathProctorTestComponent studentMetaData={studentMetaData} />
      )}
    </div>
  );
}

export default MathComponent;
