import React from "react";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { Spinner, useGetUserContext } from "./LoginActions";
import Logo from "./components/logo/Logo";
import $ from "jquery";
import { isMobile } from "react-device-detect";

export default function Sidebar({ userData, title }) {
  setTimeout(() => {
    if ($("#spinner").length > 0) {
      $("#spinner").removeClass("show");
    }
  }, 1);

  $(window).on("scroll", () => {
    if ($(this).scrollTop() > 300) {
      $(".back-to-top").fadeIn("slow");
    } else {
      $(".back-to-top").fadeOut("slow");
    }
  });
  const userContext = useGetUserContext();

  const menuClick = () => (e) => {
    const parentElement = document.querySelector(".menu-items-list");
    const elementsWithClass = parentElement.getElementsByClassName("active");
    for (const element of elementsWithClass) {
      element.classList.remove("active");
    }
    if (e.target.tagName === "H3") {
      const elementsWithClass =
        parentElement.getElementsByClassName("nav-link");
      elementsWithClass[0].classList.add("active");
    } else e.target.classList.add("active");
  };

  const listMenuItems = userContext.sidebar
    .filter((t) => t.type === userContext.data.type)
    .map((item, index) => {
      const isActive =
        index === 0 ? "nav-item nav-link active" : "nav-item nav-link";

      const icon = item.icon.includes("note")
        ? `fa fa-user me-2`
        : `fa fa-${item.icon}${
            item.icon.includes("addr")
              ? ""
              : item.icon.includes("robot")
              ? `fa fa-robot me-2`
              : item.icon.includes("edit")
              ? `fa fa-qrcode me-2`
              : item.icon.includes("solid")
              ? `fas fa-calculator`
              : item.icon.includes("atom")
              ? `fas fa-atom`
              : "-alt"
          } me-2`;
      return (
        <Link
          key={index}
          className={isActive}
          to={item.path}
          onClick={menuClick()}
        >
          <i className={icon}></i>
          {item.name}
        </Link>
      );
    });

  const toggleSidebar = () => () => {
    $(".sidebar, .contents").toggleClass("open");
  };
  return (
    <>
      <Spinner />
      <div className="sidebar pe-4 pb-3">
        <nav className="navbar bg-light navbar-light">
          <Link className="navbar-brand mx-4 mb-3" to="/" onClick={menuClick()}>
            <div className="col-auto order-0">
              <Logo
                image={`${process.env.PUBLIC_URL}/images/logo/footer-logo.png`}
              />
            </div>
          </Link>
          <div
            className="d-flex align-items-center ms-4 mb-4"
            style={{ position: "relative" }}
          >
            <div className="position-relative">
              <img
                className="rounded-circle"
                src={userData.img}
                alt=""
                style={{ width: "40px", height: "40px" }}
              />
              <div className="bg-success rounded-circle border border-2 border-white position-absolute end-0 bottom-0 p-1"></div>
            </div>
            <div className="ms-3">
              <h6 className="mb-0" id="userFL">
                {userData.fullName.slice(0, 16)}..
              </h6>
              {/* <span id="userStatus">{userData.type}</span> */}
            </div>
            {isMobile && (
              <div
                style={{
                  marginTop: "-50px",
                  marginRight: "-20px",
                }}
              >
                <a
                  className="sidebar-toggler flex-shrink-0"
                  onClick={toggleSidebar()}
                >
                  <CloseIcon />
                </a>
              </div>
            )}
          </div>
          <div className="navbar-nav w-100 menu-items-list">
            {listMenuItems}
            {/* <div className="nav-link dropdown-toggle align-middle d-flex" >
              <i className='fa fa-tachometer-alt me-1'></i>
              <Box sx={{ marginTop: "2px" }}>

                <BasicMenu title={title} />
              </Box>
            </div> */}
            {/* <div className="nav-link dropdown-toggle align-middle d-flex" >
              {title}
            </div> */}
            {/* <div className="nav-item dropdown">
              <Link className="nav-link dropdown-toggle" data-bs-toggle="dropdown" onClick={menuClick()}><i className="fa fa-laptop me-2"></i>My tests</Link>
              <div className="dropdown-menu bg-transparent border-0">
                <Link className="dropdown-item" to="/dash/sample" onClick={menuClick()}>Test</Link>
                <Link className="dropdown-item" to="/dash/sample" onClick={menuClick()}>Test</Link>
                <Link className="dropdown-item" to="/dash/sample" onClick={menuClick()}>Test</Link>
              </div>
            </div> */}
          </div>
        </nav>
      </div>
    </>
  );
}
