import React, { useEffect, useState } from "react";
import axios from "axios";
import { envData } from "../../envs";
import { isMobile } from "react-device-detect";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { fakeAuthProvider } from "../../LoginActions";
import { FaFilePdf, FaTrashAlt } from "react-icons/fa"; // Import PDF icon
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import { Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";

const Div = styled("div")(({ theme }) => ({
  ...theme.typography.button,
  // backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
  width: "40%%",
  // marginTop: "15px",
}));
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
function ArtComponent() {
  const [files, setFiles] = useState([]);
  const [artFileUploaded, setArtFileUploaded] = useState(false);
  const [msg, setMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [fileNames, setFileNames] = useState([]);
  const [fileUrls, setFileUrls] = useState([]);
  const [artTExt, setArtText] = useState("");
  const [isFirstCompleted, setIsFirstCompleted] = useState(false); // State to track completion of the first async function
  const [summary, setSummary] = useState("");
  const [updateSummary, setUpdateSummary] = useState(false);
  const userData = fakeAuthProvider.getData();
  const handleFileChange = (event) => {
    setFiles(event.target.files);
  };

  const getStudentInfo = async () => {
    const token = localStorage.getItem("token");
    try {
      const request1 = await axios.get(
        `${envData.baseApiUrl}/v1/students/student/private/list`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setFileNames(request1.data?.msg?.files);
      setSummary(request1.data?.msg?.summary);
      setIsFirstCompleted(!isFirstCompleted);
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  const deleteFile = (name, fileType) => deleted(name, fileType);
  const deleted = async (name, fileType) => {
    const token = localStorage.getItem("token");
    try {
      const res = await axios.delete(
        `${envData.baseApiUrl}/v1/students/student/private/file?${
          userData.email
        }&file=${encodeURIComponent(name)}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (fileType !== "pdf") {
        await axios.post(
          `${envData.baseApiUrl}/v1/students/student/private/summary?${userData.email}`,
          { summary: "" },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      setUpdateSummary(false);
      setArtText("");
      console.log("File deleted successfully:", res.data);
      getStudentInfo();
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const handleFileSubmit = async (event) => {
    event.preventDefault();
    if (!files.length) {
      alert("Please select a file to upload");
      return;
    }

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
    formData.append("type", "upload");
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${envData.baseApiUrl}/v1/students/student/private/upload?${userData.email}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setArtFileUploaded(!artFileUploaded);
        setOpen(true);
        setMsg("File has been uploaded");
        setFiles([]);
      } else {
        alert("Please try again!");
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      setMsg(error);
    }
  };
  const handleUpdateText = () => {
    setArtText(summary);
    setUpdateSummary(true);
  };
  const handleTextSubmit = async (event) => {
    event.preventDefault();
    setUpdateSummary(true);
    // localStorage.setItem("text", artTExt);
    // setMsg("Text description saved");
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${envData.baseApiUrl}/v1/students/student/private/summary?${userData.email}`,
        { summary: artTExt },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUpdateSummary(false);
      setArtText("");
    } catch (error) {
      console.error("Error adding text:", error);
      setMsg(error);
    }
  };
  const downloadFiles = async () => {
    const token = localStorage.getItem("token");
    const newFileUrls = [];

    for (let file of fileNames) {
      try {
        const response = await fetch(
          `${envData.baseApiUrl}/v1/students/student/private/download?${
            userData.email
          }&file=${encodeURIComponent(file)}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // if (!response.ok) throw new Error(`Failed to fetch ${file}`);

        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);

        newFileUrls.push({ name: file, url: blobUrl });
      } catch (error) {
        console.error("Error fetching file:", file, error);
      }
    }

    setFileUrls(newFileUrls); // Update state with all fetched files
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (!updateSummary) {
      getStudentInfo(); // Wait for the first function to complete
    }
  }, [artFileUploaded, updateSummary]);

  useEffect(() => {
    // if (isFirstCompleted) {
    downloadFiles();
    // }
  }, [isFirstCompleted]);

  return (
    <>
      <div
        style={{
          display: "flex",
          // alignItems: "center",
          padding: "2px 20px 15px 20px",
          background: "#001F3F",
          margin: "10px auto",
          width: "90%",
          justifyContent: "space-around",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <div>
          <div style={{ marginTop: "10px" }}>
            <Button
              component="label"
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
              style={{ padding: "7px 24px" }}
            >
              Upload art file
              <VisuallyHiddenInput type="file" onChange={handleFileChange} />
            </Button>
            <br />
            <Typography style={{ height: "23px" }}>
              {files["0"]?.name &&
                files["0"]?.name.slice(0, 7) +
                  "." +
                  files["0"]?.name.split(".").pop()}
            </Typography>
          </div>
          <Button
            style={{ padding: "7px 27px" }}
            type="submit"
            variant="contained"
            color="success"
            onClick={handleFileSubmit}
          >
            <CheckCircleOutlineIcon style={{ marginRight: "2px" }} />
            Submit art file
          </Button>
        </div>
        <div style={{ color: "white" }}>
          <Div>
            Required file uploads:{" "}
            <ol>
              {" "}
              <li>image file: png/jpg/jpeg</li>{" "}
              <li>PDF file with detailed description of your art work</li>{" "}
              <li>short summary of your art work</li>{" "}
            </ol>{" "}
          </Div>
        </div>
      </div>

      <div
        style={{
          //   display: "flex",
          //   alignItems: "center",
          padding: "20px",
          margin: "10px auto",
          width: "90%",
          justifyContent: "space-around",
          flexDirection: isMobile ? "column" : "",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          {fileUrls.map(({ name, url }, index) => {
            const isPDF = name.toLowerCase().endsWith(".pdf");

            return isPDF ? (
              <Box key={index} style={{ color: "white", margin: "20px" }}>
                <a
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="pdf-link"
                >
                  <FaFilePdf className="pdf-icon" /> {name.toUpperCase()}
                </a>
                <DeleteOutlineIcon
                  onClick={() => deleteFile(name, "pdf")}
                  sx={{
                    color: "tomato",
                    border: "1px solid #1976D2",
                    marginLeft: "3px",
                    borderRadius: "50%",
                    fontSize: "1.5rem",
                    cursor: "pointer",
                  }}
                />
              </Box>
            ) : (
              <Card sx={{ maxWidth: 345 }}>
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: "teal" }} aria-label="recipe">
                      A
                    </Avatar>
                  }
                  action={
                    <IconButton aria-label="settings">
                      <DeleteOutlineIcon
                        onClick={() => deleteFile(name)}
                        sx={{
                          float: "right",
                          color: "tomato",
                          border: "1px solid #1976D2",
                          borderRadius: "50%",
                        }}
                      />
                    </IconButton>
                  }
                  title={name ? name.split(".")[0] : ""}
                />
                <CardMedia
                  component="img"
                  height="194"
                  image={url}
                  alt="Art image"
                />

                <CardContent>
                  <Box>
                    {!summary || updateSummary ? (
                      <>
                        <TextField
                          id="outlined-multiline-static"
                          label="short summary"
                          multiline
                          rows={1}
                          inputProps={{ maxLength: 150 }}
                          style={{ width: "100%" }}
                          placeholder="Please describe your art shortly:"
                          value={artTExt}
                          onChange={(event) => setArtText(event.target.value)}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: { xs: "column", sm: "row" },
                            justifyContent: {
                              xs: "flex-start",
                              sm: "space-between",
                            },
                            width: "100%",
                            gap: 2,
                          }}
                        >
                          <span>{artTExt.length}/150 characters</span>
                          <Button
                            onClick={handleTextSubmit}
                            style={{ margin: "3px", padding: "5px 7px" }}
                            variant="contained"
                          >
                            Save text
                          </Button>
                        </Box>
                      </>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          gap: 2,
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{ color: "text.secondary" }}
                        >
                          {summary}
                        </Typography>
                        <Button
                          onClick={handleUpdateText}
                          style={{ margin: "3px", padding: "5px 7px" }}
                          variant="contained"
                        >
                          update summary
                        </Button>
                      </Box>
                    )}
                  </Box>
                </CardContent>
              </Card>
            );
          })}
        </div>
      </div>
      <div style={{ textAlign: "center" }}>
        <Snackbar
          key={"center" + "center"}
          anchorOrigin={{ vertical: "center", horizontal: "center" }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {msg}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
}

export default ArtComponent;
