import PropTypes from "prop-types";
import React, { useEffect } from "react";
import AOS from "aos";
import RobotixCatComponent from "./RobotixCategoryComponent";

const RobotixContainer = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="section section-padding">
      <div className="container">
        <div className="row pt--100 pb--80">
          <div className="col-lg-4 col-md-6 col-12">
            <div className="work-left work-details" data-aos="fade-up">
              <div className="portfolio-main-info">
                <h2 className="title">
                  Exam
                  <br /> Details
                </h2>
                <div className="work-details-list mt-12">
                  <div className="details-list">
                    <img
                      src="images/categoryIcons/date.png"
                      alt=""
                      width="40px"
                    />
                    &nbsp;
                    <label>Date</label>
                    <span style={{ textAlign: "center" }}>June/2025</span>
                  </div>

                  <div className="details-list">
                    <img
                      src="images/categoryIcons/grade.png"
                      alt=""
                      width="40px"
                    />
                    &nbsp;
                    <label>Grades</label>
                    <span style={{ paddingLeft: "45px" }}>3-12</span>
                  </div>

                  <div className="details-list">
                    <img
                      src="images/categoryIcons/location.png"
                      alt=""
                      width="40px"
                    />
                    &nbsp;
                    <label>Location</label>
                    <span style={{ textAlign: "center" }}>UCLA, CA</span>
                  </div>

                  {/* <div className="details-list">
                    <label>Awards</label>
                    <span>asdasdasd</span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-7 col-md-6 offset-lg-1 col-12">
            <div className="work-left work-details mt-6">
              <div className="work-main-info">
                <div className="work-content">
                  <h3 className="title" data-aos="fade-up">
                    RobotixCel
                  </h3>

                  <div className="desc mt-8">
                    <ul>
                      <li>Junior Level (Grades 3-5):</li>
                      <li>Intermediate Level (Grades 6-8):</li>
                      <li>Senior Level (Grades 9-12):</li>
                    </ul>

                    <h5>Competition Rounds:</h5>
                    <h6>Preliminary Round:</h6>
                    <ul>
                      <li>
                        Online submission of robot designs and documentation.
                      </li>
                      <li>
                        Students should upload a decent-quality video
                        demonstration of their robots, with a maximum duration
                        of 150 seconds. The video should be uploaded to YouTube
                        as a private link, ensuring that the entire duration
                        includes both the starting and final points, clearly
                        showing how the robot operates.
                      </li>
                      <li>
                        Participants create detailed designs, schematics, and
                        explanations of their robots.
                      </li>
                      <li>
                        Evaluation based on creativity, functionality,
                        innovation, and practicality of the design.
                      </li>
                      <li>
                        Duration: To be determined by XCEL, typically 60-90 days
                        for submission.
                      </li>
                      <li>
                        Scoring: Judges review and score submissions based on
                        predefined criteria.
                      </li>
                      <li>
                        Medals: Gold, Silver, Bronze awarded based on percentile
                        ranking of submissions.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div
              className="custom-column-thumbnail mt-lg-14 mt-1"
              data-aos="fade-up"
            >
              <h5>Global Finals:</h5>
              <ul>
                <li>
                  On-site showcase and presentation of robot designs held in the
                  USA.
                </li>
                <li>
                  Finalists present their robot designs to a panel of judges.
                </li>
                <li>
                  Emphasis on explaining design choices, demonstrating
                  functionality, and addressing questions from the judges.
                </li>
                <li>
                  Participants may also showcase physical prototypes or models
                  of their robots.
                </li>
                <li>
                  Judged by a panel of experts in robotics engineering and
                  design.
                </li>
                <li>
                  Medals, certificates, and cash prizes awarded to top
                  performers based on the judging criteria.
                </li>
              </ul>
              <h5>Categories</h5>
              <br />
              <RobotixCatComponent />
              {/* <p>
                Evaluating robotics design according to grade levels involves
                assessing various aspects of the design process and the final
                robot product, taking into account the age, experience, and
                technical proficiency of the students. Here&apos;s a breakdown
                of how to evaluate robotics design at different grade levels:
              </p> */}
              {/* <h5>Grades 1-5 (Junior Level):</h5>
              <ol>
                <li>Creativity and Innovation:</li>
                <ul>
                  <li>
                    Assess the originality and creativity of the robot design.
                    Look for unique features or functionalities that demonstrate
                    imaginative thinking.
                  </li>
                </ul>
                <li>Functionality:</li>
                <ul>
                  <li>
                    Evaluate whether the robot performs its intended tasks
                    effectively. Focus on simple actions such as moving forward,
                    turning, and interacting with objects.
                  </li>
                </ul>
                <li>Safety:</li>
                <ul>
                  <li>
                    Ensure that the robot design prioritizes safety, especially
                    considering the age of the students. Check for any sharp
                    edges, loose parts, or other hazards.
                  </li>
                </ul>
                <li>Aesthetics:</li>
                <ul>
                  <li>
                    Consider the appearance of the robot. While not as crucial
                    as functionality, a visually appealing design can enhance
                    engagement and pride in the project.
                  </li>
                </ul>
                <li>Documentation:</li>
                <ul>
                  <li>
                    Assess the clarity and completeness of the documentation
                    accompanying the robot design, including drawings, diagrams,
                    and written explanations.
                  </li>
                </ul>
              </ol>

              <h5>Grades 6-8 (Intermediate Level):</h5>
              <ol>
                <li>Technical Complexity:</li>
                <ul>
                  <li>
                    Evaluate the complexity of the robot design in terms of
                    mechanical, electrical, and programming components. Consider
                    factors such as the number of sensors, motors, and actuators
                    used.
                  </li>
                </ul>
                <li>Robustness:</li>
                <ul>
                  <li>
                    Assess the durability and reliability of the robot design.
                    Evaluate whether it can withstand minor collisions, rough
                    terrain, or other environmental challenges.
                  </li>
                </ul>
                <li>Programming Skills:</li>
                <ul>
                  <li>
                    Evaluate the sophistication of the programming code
                    controlling the robot. Look for evidence of loops,
                    conditional statements, sensor integration, and autonomous
                    behavior.
                  </li>
                </ul>
                <li>Problem-Solving:</li>
                <ul>
                  <li>
                    Assess the students&apos; ability to identify and overcome
                    design challenges or limitations. Consider how they
                    iteratively refine their designs based on testing and
                    feedback.
                  </li>
                </ul>
                <li>Teamwork (if applicable):</li>
                <ul>
                  <li>
                    If the project involves teamwork, evaluate how effectively
                    the team collaborates, communicates, and distributes tasks
                    among members.
                  </li>
                </ul>
              </ol>

              <h5>Grades 9-12 (Senior Level):</h5>
              <ol>
                <li>Engineering Design Process:</li>
                <ul>
                  <li>
                    Evaluate how well students follow the engineering design
                    process, including problem identification, brainstorming,
                    prototyping, testing, and iteration.
                  </li>
                </ul>
                <li>Innovation and Originality:</li>
                <ul>
                  <li>
                    Assess the level of innovation demonstrated in the robot
                    design. Look for novel solutions to challenges or unique
                    applications of technology.
                  </li>
                </ul>
                <li>Integration of Technologies:</li>
                <ul>
                  <li>
                    Evaluate how effectively students integrate multiple
                    technologies (e.g., sensors, actuators, microcontrollers)
                    into their robot design to achieve desired functionalities.
                  </li>
                </ul>
                <li>Real-World Applications:</li>
                <ul>
                  <li>
                    Consider the practicality and relevance of the robot design
                    to real-world problems or applications. Evaluate whether the
                    design addresses a specific need or challenge.
                  </li>
                </ul>
                <li>Presentation Skills:</li>
                <ul>
                  <li>
                    Assess students&apos; ability to communicate their design
                    process, rationale, and results effectively through
                    presentations or demonstrations. Evaluate clarity,
                    organization, and engagement.
                  </li>
                </ul>
              </ol>

              <h5>Overall Considerations:</h5>
              <ul>
                <li>
                  Fairness: Ensure that evaluation criteria are fair and
                  equitable, taking into account the resources, time
                  constraints, and prior experience of students.
                </li>
                <li>
                  Feedback: Provide constructive feedback to students,
                  highlighting strengths and areas for improvement in their
                  robot designs.
                </li>
                <li>
                  Encouragement: Acknowledge and celebrate students&apos;
                  efforts and achievements, fostering enthusiasm and continued
                  interest in robotics.
                </li>
              </ul> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

RobotixContainer.propTypes = {
  data: PropTypes.object,
};

export default RobotixContainer;
