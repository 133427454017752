import React, { useState, Suspense, lazy } from "react";
import AOS from "aos";

import {
  BrowserRouter as Router,
  createBrowserRouter,
  RouterProvider,
  redirect,
  useRouteError,
} from "react-router-dom";
import {
  SignupPage,
  loginAction,
  loginLoader,
  protectedLoader,
  fakeAuthProvider,
  signupAction,
} from "./LoginActions";
import HomeOne from "./pages/HomeOne";
import CognitiveAttitudes from "./pages/CognitiveAttitudes";
import Robotix from "./pages/Robotix";
import Math from "./pages/Math";
import Art from "./pages/Art";
import Dash, { Dash_sub } from "./Dash";
import BlogGrid from "./pages/BlogGrid";
import BlogDetails from "./pages/BlogDetails";
import Contact from "./pages/Contact";
import LoginPage from "./LoginPage/LoginWrapper";
import About from "./pages/About";
import MyExamList from "./components/studentExamList/MyExamList";
import PaymentPage from "./components/payment/paymentPage";
import PaymentStatusPage from "./components/payment/paymentStatusPage";
import StudentInfo from "./components/student/studentInfo";
import StudentRow from "./components/student/studentProfileInfo";
import RoboticsRegistration from "./components/student/roboticsRegistration";
import ArtComponent from "./components/student/artComponent";
import ExamResult from "./components/examResults/examResult";
import NavScrollTop from "./components/NavScrollTop";
import StudentListPage from "./components/Instructor/StudentListPage";
import InstructorProfilePage from "./components/Instructor/instructorProfile/page";
import ChecklistComponent from "./components/ProctorComponents/proctor";
import MathComponent from "./components/ExamPage/MathComponent";
import CogatComponent from "./components/ExamPage/CogatComponent";
import PracticeMathComponent from "./components/PracticeExamPage/PracticeMathComponent";
import PracticeCogatComponent from "./components/PracticeExamPage/PracticeCogatComponent";
import GeneralDataTable from "./components/AllStudentsTable/generalData";
import FirstLocalRoundResults from "./pages/FirstLocalRoundResults";
const router = createBrowserRouter([
  {
    path: "/",
    Component: HomeOne,
    errorElement: <ErrorPage />,
  },
  {
    path: "/about",
    Component: About,
    errorElement: <ErrorPage />,
  },
  {
    path: "/contact",
    Component: Contact,
    errorElement: <ErrorPage />,
  },
  {
    path: "/cognitive-abilities",
    Component: CognitiveAttitudes,
    errorElement: <ErrorPage />,
  },
  {
    path: "/robotics",
    Component: Robotix,
    errorElement: <ErrorPage />,
  },
  {
    path: "/math-analysis",
    Component: Math,
    errorElement: <ErrorPage />,
  },
  {
    path: "/art",
    Component: Art,
    errorElement: <ErrorPage />,
  },

  {
    path: "/resources",
    Component: BlogGrid,
    errorElement: <ErrorPage />,
  },
  {
    path: "/results",
    Component: FirstLocalRoundResults,
    errorElement: <ErrorPage />,
  },
  {
    path: `${process.env.PUBLIC_URL + "/resources/:id"}`,
    Component: BlogDetails,
    errorElement: <ErrorPage />,
  },
  {
    id: "dash",
    path: "/dash",
    Component: Dash,
    loader: protectedLoader,
    children: [
      {
        index: true,
        Component: Dash_sub,
      },
      {
        path: "payments",
        Component: PaymentPage,
      },
      {
        path: "student-list",
        Component: GeneralDataTable,
      },
      {
        path: "student",
        Component: StudentInfo,
      },
      {
        path: "studentTable",
        Component: StudentRow,
      },
      {
        path: "results",
        Component: ExamResult,
      },
      // {
      //   path: "student-list",
      //   // Component: StudentListPage,
      //   Component: GeneralStudentTable,
      // },
      {
        path: "profile-info",
        Component: InstructorProfilePage,
      },
      {
        path: "order",
        Component: PaymentStatusPage,
      },
      {
        path: "robotics",
        Component: RoboticsRegistration,
      },
      {
        path: "art",
        Component: ArtComponent,
      },
      {
        path: "math",
        Component: MathComponent,
      },
      {
        path: "cogat",
        Component: CogatComponent,
      },
      {
        path: "practiceMath",
        Component: PracticeMathComponent,
      },
      {
        path: "practiceCogat",
        Component: PracticeCogatComponent,
      },
      // {
      //   path: "draftStudentTable",
      //   Component: GeneralStudentTable,
      // },
    ],
  },
  {
    path: "login",
    // action: loginAction,
    // loader: loginLoader,
    Component: LoginPage,
  },
  {
    path: "register",
    // action: signupAction,
    Component: SignupPage,
  },
  // {
  // 	path: 'register',
  // 	// action: signupAction,
  // 	Component: RegisterUserPage,
  // },
]);

export default function Root() {
  return (
    <RouterProvider router={router} fallbackElement={<p>Initial Load...</p>} />
  );
}

function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page" className="container">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}
